import React, { PropsWithChildren, ReactElement, useEffect, useRef } from "react";
import styles from "./HeroBlurred.module.scss";
import useWindowWidth, { heroDesktopMinWidth } from "../../../hooks/useWindowWidth"
import useIsBrowser from "../../../hooks/useIsBrowser"

interface HeroMediaProps {
    data: any;
    height?: number
}

const HeroBlurred = (props: PropsWithChildren<HeroMediaProps>): any => {
    const {
        hero_background,
        hero_title,
        hero_text_alignment,
        hero_overlay_color,
        hero_text_color,
        mobile_background,
        hero_description,
        hero_alt_text,
        hero_alt_text_mobile
    } = props.data;

    const { isBrowser, key } = useIsBrowser();
    const isDesktop = useWindowWidth(heroDesktopMinWidth);

    let isImage: boolean, isVideo: boolean;
    const heroRef = useRef<HTMLDivElement>(null as any)

    if (isDesktop) {
        isImage = hero_background.kind === "image";
        isVideo = hero_background.kind === "document";
    } else {
        isImage = mobile_background.kind === "image";
        isVideo = mobile_background.kind === "document";
    }

    const setTextAlignment = (): string => {
        switch (hero_text_alignment) {
            case "Center":
                return styles.alignedCenter;
            case "Right":
                return styles.alignedRight;
            default:
                return styles.alignedLeft;
        }
    };

    const heroAltText = (): string => 
    {
        let altText = "";
        if (isDesktop && hero_alt_text?.length > 0) {
            altText = hero_alt_text[0].text;
        } else if (!isDesktop && hero_alt_text_mobile?.length > 0) {
            altText = hero_alt_text_mobile[0].text;
        }
        return altText;
    }

    if (!isBrowser) return null;

    return (
        <div
            key={key}
            ref={heroRef}
            className={`${styles.heroMedia} print-hidden ${isVideo ? styles.withVideo : styles.withImage}`}
            style={{
                ["--heroOverlayColor" as string]: hero_overlay_color,
                ["--heroTextColor" as string]: hero_text_color,
                ["--heroHeight" as string]: props.height ? `${props.height}vh` : "",
            }}
        >
            <div className={styles.blurred}>
                {hero_title && <h1 className={setTextAlignment()}>{hero_title[0]?.text}</h1>}
                {hero_description && <p>{hero_description[0]?.text}</p>}
            </div>

            {isImage && <img src={isDesktop ? hero_background.url : mobile_background.url} alt={heroAltText()} />}
            {isVideo && <video autoPlay muted loop src={isDesktop ? hero_background.url : mobile_background.url} />}
        </div>
    );
};

export default HeroBlurred;
