import React, { forwardRef, ReactElement, useEffect, useRef, useState } from "react";
import useWindowWidth from "../../../hooks/useWindowWidth";
import { arrows } from "../../../shared/icons"
import styles from "./DateNavMobile.module.scss"

interface navArray {
    UTCdate: Date,
    classesData: any[],
    dayName: dayAndMonthNames,
    monthName: dayAndMonthNames,
    numberDate: string
}

interface dayAndMonthNames {
    large: string, short: string
}

interface DateNavProps {
    dates: any,
    current: any,
    onDateClick: (day: Date) => void
}

const DateNavMobile = forwardRef<HTMLElement, DateNavProps>((props, ref): ReactElement => {
    const scrollNav = useRef<number>(0)

    const { dates, current, onDateClick } = props
    const isDesktop = useWindowWidth(1200);

    const datesCopy = [...dates]
    const datesCopyMobile = [...dates]

    const firstSeven = datesCopy.splice(0, (dates.length / 2))
    const secondSeven = datesCopy.splice(0, (dates.length))

    const [navArray, setNavArray] = useState(new Array<navArray>());


    useEffect(() => {
        if (isDesktop) {
            setNavArray(firstSeven);
        } else {
            setNavArray(datesCopy);
        }
    }, [dates])

    useEffect(() => {
        if (isDesktop) {
            if (current && current?.numberDate === secondSeven[0].numberDate) {
                setNavArray(secondSeven)
            } else if (current && current?.numberDate === firstSeven[firstSeven.length - 1].numberDate) {
                setNavArray(firstSeven)
            };
        }

        let left = document.querySelectorAll(`.${styles.focusDay}`)[0]?.getBoundingClientRect().left
        let width = document.querySelectorAll(`.${styles.focusDay}`)[0]?.getBoundingClientRect().width
        let totalItemNav = left + width
        let scrollWidth = document.querySelector('#scrollNavigation')?.clientWidth
        if (scrollWidth && totalItemNav > scrollWidth) {
            scrollNav.current = scrollNav.current + width
            document.querySelector('#scrollNavigation')?.scrollTo({ left: scrollNav.current, behavior: 'smooth' })
        }
        else if (left < 50) {
            scrollNav.current = scrollNav.current - width
            document.querySelector('#scrollNavigation')?.scrollTo({ left: scrollNav.current, behavior: 'smooth' })
        }
    }, [current])

    useEffect(() => {
        if (isDesktop) {
            if (current) firstSeven.find((x) => x.numberDate === current.numberDate) ? setNavArray(firstSeven) : setNavArray(secondSeven)
        } else {
            setNavArray(datesCopyMobile)
        }
    }, [isDesktop])


    const handlesYScroll = (e: any) => {
        let scrollPosition = e.target.scrollLeft + e.target.offsetWidth

        if (e.target.scrollLeft <= 50) {
            document.querySelector('#scrollNavigation')?.classList.add(styles.removeBorderLeft)
        } else if (e.target.scrollLeft >= 50) {
            document.querySelector('#scrollNavigation')?.classList.remove(styles.removeBorderLeft)
            if (scrollPosition >= e.target.scrollWidth - 50) {
                document.querySelector('#scrollNavigation')?.classList.add(styles.removeBorderRight)
            } else if (scrollPosition < e.target.scrollWidth - 50) {
                document.querySelector('#scrollNavigation')?.classList.remove(styles.removeBorderRight)
            }
        }

    }

    const handleClickDate = (e: any, date: any) => {
        scrollNav.current = e.currentTarget.parentElement?.scrollLeft
        onDateClick(date)
    }

    return (
        <nav {...{ ref }} className={`${styles.DateNavWrapper} print-hidden`}>
            <div id="scrollNavigation" className={`${styles.elementsNavWrapper} ${!isDesktop ? styles.mobileGrid : ""} ${styles.removeBorderLeft}`}
                onScroll={(e) => handlesYScroll(e)}
            >

                {isDesktop && <div className={`${styles.elementNav} ${styles.arrow} ${firstSeven[0] === navArray[0] ? styles.disabled : ""}`} onClick={() => setNavArray(firstSeven)} >
                    {arrows.left}
                </div>}

                {navArray.map((date: any, i: number) => (
                    <div
                        className={` ${styles.elementNav} 
                    ${(firstSeven[0] === navArray[0] && i === 0) ? styles.currentDay : ""} 
                    ${date.numberDate === current?.numberDate ? styles.focusDay : ""}
                    `}
                        key={i}
                        onClick={(e) => handleClickDate(e, date)}
                    >
                        <p>{date.numberDate}</p>
                        <p>{firstSeven[0] === navArray[0] && i === 0 ? "TODAY" : date.dayName.short}</p>
                    </div>
                ))}

                {isDesktop && <div className={`${styles.elementNav} ${styles.arrow} ${secondSeven[0] === navArray[0] ? styles.disabled : ""} `} onClick={() => setNavArray(secondSeven)} >
                    {arrows.right}
                </div>}

            </div>
        </nav>
    )
});

export default DateNavMobile;