import React, { PropsWithChildren, ReactElement } from "react";
import { errorFilterIcon } from "./../../../shared/icons"
import ClassCard from "./ClassCard";
import styles from "./Day.module.scss"

interface props {
    numberDate?: string;
    monthName?: any;
    dayName?: any;
    UTCdate?: Date;
    classesData?: any;
    handleClass?: any;
    hourSplitter?: any;
    haveClasses: boolean;
    isMobile?: boolean;
    spinner? : (param:boolean) => void;
    reserveModal ? : (param:boolean) => void;
}

const Day = (props: PropsWithChildren<props>): ReactElement => {
    const { numberDate, monthName, dayName, classesData, handleClass, hourSplitter, haveClasses, spinner, reserveModal} = props

    return (
        <div className={`${haveClasses ? styles.dayWrapper : styles.AdviceWrapper} ${!classesData?.length ? `${styles.dayDisable}` : ''} `} id={numberDate}>
            { !haveClasses && <div className={styles.advice}>
                {errorFilterIcon}
                <p>There are no classes with these filter options.</p>
                <p>Try removing some!</p>
            </div>}

            {haveClasses &&
                <h2 className={`${styles.dateTitle} ${!classesData.length ? `print-hidden`: 'print-block'}`}>
                    {dayName.large}, {monthName.short} {numberDate?.split("/")[1]}
                </h2>
            }

            {  classesData && classesData.map((classData: any, i: number) => (
                <ClassCard {...classData} 
                key={classData.eventInstanceId} 
                onPointer={() => handleClass({ classData, dayName, monthName, numberDate })} 
                hourSplitter={hourSplitter} 
                loading={spinner}
                reserveModal={reserveModal}/>
            ))
            }
            { haveClasses  && !classesData.length && <div className={`${styles.minorAdvice} print-hidden`}>
                <p>No classes today.</p>
            </div>}
        </div>
    )
};

export default Day;