import api from "./DashboardApiService";

class MosoService {
    private static readonly baseUrl: string = "scheduler";

    public static async getAllClasses(): Promise<any> {
        return await api.get(`${this.baseUrl}/classes/`);
    };
}

export default MosoService;