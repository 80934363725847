import React, { PropsWithChildren, ReactElement } from "react";
import { WarningService } from "../../../services/WarningService";
import { WarningComponentProps } from "./Interfaces/PropsInterfaces";

const WarningComponent = (
    props: PropsWithChildren<WarningComponentProps>
): ReactElement => {
    WarningService(props.template, props.message);

    return <>{props.fallbackWidget}</>;
};

export default WarningComponent;
