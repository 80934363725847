import React, {
    PropsWithChildren,
    ReactElement,
    useState,
    useEffect,
    useRef
} from "react";
import _ from "lodash"
import PrismicService from "../../services/PrismicService";
import MosoService from "../../services/MosoService";
import useWindowWidth from "../../hooks/useWindowWidth";
import { dateHandler, getSeoDescription, getSeoKeywords, getSeoTitle, handleSplit, isWindow } from "../../shared/helpers"
import { printerIcon, FilterIcon, clearFilterIcon, searchIcon } from "./../../shared/icons";
import styles from "./Scheduler.module.scss";
import Helmet from "../../components/Helmet";
import HeroBlurred from "../../components/Widgets/HeroBlurred";
import DateNav from "./DateNav";
import DateNavMobile from "./DateNavMobile";
import FilterNav from "./FilterNav"
import Day from "./Day";
import ClassPopup from "./Day/ClassPopup";
import { useEventListener } from "../../hooks/UseEventListener";
import WarningComponent from "../../components/shared/FallbackWarnComponent";
import WebAccountService from "../../services/WebAccountService";
import MembersModal from "../Members_page/Shared/MembersModal";
import { navigate } from "gatsby";

const scrollThresholdMin = 0;
const scrollThresholdMax = -200;
const autoScrollThreshold = 5;

//#region interface

interface DayType
{
    UTCdate: Date,
    classesData: Array<Object>,
    dayName: Object,
    monthName: Object,
    numberDate: string
}

interface FilterObject
{
    classes?: Array<ClassesObject>,
    instructors?: Array<InstructorObject>,
    categories?: Array<CategoriesObject>,
    gyms?: Array<GymsObject>
}

interface ClassesObject
{
    className: string, eventId: number, categoryId?: number, eventInstanceId?: number, quantity?: number
}
interface InstructorObject
{
    instructor: string, roleId: number, quantityClasses: number
}
interface CategoriesObject
{
    categoryName: string, categoryId: string, quantityClasses: number, classes: Array<ClassesObject>, uniqueClasses?: Array<ClassesObject>
}
interface GymsObject
{
    location: string, businessUnitCode: number
}


export interface IReserveModal {
    title : string,
    message : string,
    registered ? : boolean,
}
//#endregion

const Scheduler = (props: PropsWithChildren<DayType>): ReactElement =>
{
    const [pageData, setPageData] = useState(null as any);
    const [currentClass, setCurrentClass] = useState(null as any);
    const [classesByDateFiltered, setClassesByDateFiltered] = useState([]);
    const [classesByDate, setClassesByDate] = useState(new Array<DayType>());
    const [classFilter, setClassFilter] = useState([]);
    const [filters, setFilters] = useState({} as any);
    const [prevFilter, setPrevFilter] = useState({} as any);
    const [actualCategoriesAndClasses, setCategoriesAndClasses] = useState([] as any);
    const [currentDay, setCurrentDay] = useState(null as any);
    const [navArray, setNavArray] = useState(new Array<DayType>());
    const [showSpinner, setSpinner] = useState(true);
    const [showError, setError] = useState(false);
    const [reserveModal, setReserveModal] = useState<IReserveModal | boolean>(false);
    const [isLogged, setIsLogged] = useState( isWindow ? localStorage.getItem("jwt-token") : null);
    const [unauthorized, setUnauthorized] = useState<boolean>(false)
    
    const dateNavRef = useRef<HTMLElement>(null as any);
    const isFirstRender = useRef(false);
    const copyDataBase = useRef([]); //commented for test
    const containerRef = useRef<HTMLDivElement>(null);
    const FirstClassesByDateRef = useRef(false);
    const currentSearchFilters = useRef({});
    const scrollPos = useRef(0);

    const isDesktop = useWindowWidth(1199);
    
    
    //#region effects
    useEffect(() =>
    {
        if (!FirstClassesByDateRef.current && classesByDate.length > 0)
        {
            setNavArray(classesByDate);
            FirstClassesByDateRef.current = true;
        }
    }, [classesByDate])

    useEffect(() =>
    {
        const getPrismicData = async () =>
        {
            let prismicService;
            let schedulerPageData: any;

            await Promise.resolve(
                PrismicService.getInstance().then(
                    (val) =>
                    {
                        prismicService = val;
                        return Promise.resolve(prismicService.getDocuments("scheduler_page"));
                    }
                ).then(
                    (val) => schedulerPageData = val
                )
            )

            schedulerPageData.results.length ? setPageData(schedulerPageData.results[0].data) : " ";
        };
        getPrismicData();

        //commented for test
        const getMosoData = async () =>
        {
            try
            {
                // -- If member is logged, call service with token  
                const results = !isLogged ? await MosoService.getAllClasses() : await WebAccountService.getAllClasses();
                copyDataBase.current = results.data;
                setClassesByDateFiltered(buildCategoryArray(copyDataBase.current));
                let dataCategories: any = buildFilter(copyDataBase.current, false);
                setCategoriesAndClasses(dataCategories);
                setSpinner(false);

            }
            catch  (error : any) {
                console.log(error);
                if(error.response.status === 401 ){
                    try{
                        isWindow && localStorage.removeItem("jwt-token");
                        setSpinner(false);
                        setUnauthorized(true);

                        // setReserveModal({
                        //     title : "Unauthorized",
                        //     message : `Your session has expired or your credentials are invalid, you can view the classes but not book them.
                        //     If you want to book, please log in again.`
                        // })
                    }
                    catch{
                        setSpinner(false);
                        setError(true);
                    }
                }
            }

        }
        getMosoData();
    }, []);

    useEffect(() =>
    {
        if (classesByDateFiltered.length)
        {
            isFirstRender.current = true;
            buildFilter(copyDataBase.current, true);
            dataByDate(copyDataBase.current);
        }
        if (isFirstRender.current && !classesByDateFiltered.length)
        {
            dataByDate(copyDataBase.current);
        }

        if (Object.keys(currentSearchFilters.current).length > 0 && classesByDateFiltered.length)
        {
            filterByState();
            currentSearchFilters.current = {};
        }

    }, [classesByDateFiltered]);

    useEffect(() =>
    {
        if (isFirstRender.current)
        {

            if (Object.keys(filters).length > 0)
            {

                let stringParam: string = "?";

                Object.keys(filters).forEach((key: string, index: number) =>
                {

                    let newKey: string;
                    key === "startDate" ? newKey = "time" : newKey = key;

                    let stringNumbers: string = `${index > 0 ? "&" + newKey : newKey}=`;

                    filters[key].forEach((el: number | object, index: number) =>
                    {
                        let elem: string = typeof el === 'object' ? JSON.stringify({ ...el }) : `${el}`;
                        switch (el.start)
                        {
                            case 5:
                                elem = "morning";
                                break;
                            case 12:
                                elem = "afternoon";
                                break;
                            case 17:
                                elem = "evening";
                                break;
                            default:
                                break;
                        }
                        stringNumbers = stringNumbers + `${(index === 0) ? elem : "," + elem}`;

                    });

                    stringParam += stringNumbers;
                });

                const currentParams = "?" + window.location.href.split("?")[1];
                if(currentParams !== stringParam){
                    window.history.pushState({}, document.title, stringParam);
                }
                filterByState();

            } else
            {

                setClassesByDateFiltered(buildCategoryArray(copyDataBase.current));

                setCategoriesAndClasses(buildFilter(copyDataBase.current, false));

                const urlSplit = window.location.href.split("?");
                if (urlSplit.length < 2)
                {
                    window.history.pushState({}, document.title, urlSplit[0]);
                }

            }
        }
    }, [filters])

    useEffect(() =>
    {
        if (currentClass)
        {
            if (!dateNavRef?.current) return;

            dateNavRef.current.scrollIntoView();
            document.body.classList.add("no-overflow");
        }

        return () => document.body.classList.remove("no-overflow");
    }, [currentClass]);


    //checkQueryParams
    useEffect(() =>
    {
        let queryFilters: any = {}
        const params = new URLSearchParams(props.location.search);

        for (var key of params.keys())
        {
            let newFilterArr: Array<Number | Object> = [];
            let newKey: string;

            key === "time" ? newKey = "startDate" : newKey = key;

            params.get(key)?.split(",").forEach(elem =>
            {
                switch (elem)
                {
                    case "morning":
                        newFilterArr.push({ start: 5, end: 12 });
                        break;
                    case "afternoon":
                        newFilterArr.push({ start: 12, end: 17 });
                        break;
                    case "evening":
                        newFilterArr.push({ start: 17, end: 23 });
                        break;
                    default:
                        newFilterArr.push(parseInt(elem));
                        break;
                };
            });

            queryFilters = { ...queryFilters, [newKey]: newFilterArr };
        }

        currentSearchFilters.current = queryFilters;

        setFilters(queryFilters);

    }, [props.location])

    //#endregion

    //#region functions

    const handleScroll = () =>
    {
        scrollPos.current = document.documentElement.scrollTop;

        //set current day by scroll
        if (currentDay)
        {
            const headerHeight = parseInt(getComputedStyle(document.documentElement).getPropertyValue('--headerHeight'));
            const dateNavHeight = parseInt(getComputedStyle(document.documentElement).getPropertyValue('--dateNav-height'));
            const topOffset = headerHeight + dateNavHeight;

            for (let childDayEl of containerRef.current.children)
            {
                const day = classesByDate.find((x: any) => x.numberDate === childDayEl.id);
                const elemOffset = childDayEl.getBoundingClientRect().top - topOffset;
                const dayIsCurrent = childDayEl.id === currentDay.numberDate;
                const topReached = (elemOffset <= scrollThresholdMin && elemOffset >= scrollThresholdMax);

                if (topReached && !dayIsCurrent)
                {
                    setCurrentDay(day);
                } else if (elemOffset > 0 && dayIsCurrent)
                {
                    const currentDayIndex = classesByDate.findIndex(e => e.numberDate === currentDay.numberDate);
                    const prevDay = currentDayIndex > 0 ? classesByDate[currentDayIndex - 1] : classesByDate[currentDayIndex];
                    setCurrentDay(prevDay);
                }
            }
        }
    };

    //scroll to day
    const selectDay = (day: any) =>
    {
        const headerHeight = parseInt(getComputedStyle(document.documentElement).getPropertyValue('--headerHeight'));
        const dateNavHeight = parseInt(getComputedStyle(document.documentElement).getPropertyValue('--dateNav-height'));
        const topOffset = headerHeight + dateNavHeight;
        const dayContainer = Array.from(containerRef.current.children).find(x => x.id === day.numberDate);
        const dayContainerTop = dayContainer?.getBoundingClientRect().top;
        const slideTop = dayContainerTop - topOffset + scrollPos.current + autoScrollThreshold;
        window.scrollTo({ top: slideTop, left: 0, behavior: "smooth" });
    };

    const escHandler = (e: any) =>
    {
        if (e.keyCode === 27 && currentClass != null)
        {
            setCurrentClass(null);
        }
    };

    //build array ordered by date
    const buildCategoryArray = (Classes: any) =>
    {

        let newClassesArray: any = [];

        Classes.forEach((instance: any) =>
        {
            let currentDate = new Date(instance.date);
            let simplifiedDate = dateHandler(currentDate).numberDate;

            let found: boolean = false;
            let foundClassIndex: number = 0;

            for (let i = 0; i < newClassesArray.length; i++)
            {
                if (newClassesArray[i]?.numberDate == simplifiedDate)
                {
                    found = true;
                    foundClassIndex = i;
                    break;
                }
            }

            if (found)
            {
                newClassesArray[foundClassIndex].classesData.push(instance);
            } else
            {
                newClassesArray.push(dateHandler(currentDate, instance));
            }
        });

        newClassesArray.sort((a: any, b: any) => a.UTCdate - b.UTCdate);

        //Sort all classes of the day by startDate prop 
        for (const dayClasses of newClassesArray){
            dayClasses.classesData.sort((a:any, b:any) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime())
        }

        // we have always 14th days from today
        return newClassesArray;
        //return newClassesArray
    }

    // convert AM/PM format to 24hs for compare filters
    const timeConvert = (time: string) =>
    {
        let finalHour: number;
        // Check correct time format and split into components
        let newTime = time.split(':');
        let hour = newTime[0];

        let format = newTime[2].substring(3);

        if (format === "AM")
        {
            finalHour = parseInt(hour);
        } else
        {
            finalHour = parseInt(hour) + 12;
        }

        return finalHour;

    }

    //filter cleanData by the filter state, you can actualize state classFilter or only display info (to show quantity)
    const buildFilter = (Classes: any, stateReLoad: boolean) =>
    {

        let filterObject: FilterObject = {
            classes: new Array<ClassesObject>(),
            instructors: new Array<InstructorObject>(),
            categories: new Array<CategoriesObject>(),
            gyms: new Array<GymsObject>()
        };

        Classes.forEach((item: any) =>
        {

            //instructors
            if (filterObject.instructors?.find((x: any) => x.roleId === item.roleId))
            {
                let index = filterObject.instructors.findIndex((i: any) => i.roleId === item.roleId);
                filterObject.instructors[index].quantityClasses = filterObject.instructors[index].quantityClasses + 1;
                //add quantity of classes
            }
            else if (item.instructor)
            {
                filterObject.instructors?.push({ "instructor": item.instructor, "roleId": item.roleId, "quantityClasses": 1 });
            }
            //category
            if (filterObject.categories?.find((x: any) => x.categoryId === item.categoryId))
            {
                let index = filterObject.categories.findIndex((i: any) => i.categoryId === item.categoryId);
                let encountered = filterObject.categories[index].uniqueClasses?.find((i: any) => i.eventId === item.eventId);
                filterObject.categories[index].classes.push({ "className": item.className, "eventId": item.eventId });
                //add quantity of classes
                filterObject.categories[index].quantityClasses = filterObject.categories[index].quantityClasses + 1;
                // if we have the same class but different instance push only one className
                if (!encountered)
                {
                    filterObject.categories[index].uniqueClasses?.push({ "className": item.className, "eventId": item.eventId });
                }
            } else
            {
                filterObject.categories?.push({ "categoryName": item.categoryName, "categoryId": item.categoryId, "quantityClasses": 1, "classes": [{ "className": item.className, "eventId": item.eventId }], "uniqueClasses": [{ "className": item.className, "eventId": item.eventId }] });
            }
            //classes
            if (filterObject.classes?.find((x: any) => x.eventId === item.eventId))
            {
                let index = filterObject.classes.findIndex((i: any) => i.eventId === item.eventId);
                //add quantity of classes
                filterObject.classes[index].quantity = filterObject.classes[index].quantity + 1;
                //do nothing
            } else
            {
                filterObject.classes?.push({ "className": item.className, "eventId": item.eventId, "categoryId": item.categoryId, quantity: 1 });
            }
            //category
            if (filterObject.gyms?.find((x: any) => x.businessUnitCode === item.businessUnitCode))
            {
                //do nothing
            } else
            {
                filterObject.gyms?.push({ "location": item.location, "businessUnitCode": item.businessUnitCode });
            }

        })

        // if stateReload, false only need to actualize filter options to display
        return stateReLoad ? setClassFilter(filterObject as any) : filterObject
    }

    // set states for filters
    const filterSelect = (field: string, value: number) =>
    {

        if (!filters[field])
        {
            filters[field] = [];
        }

        let indexRole = Object.keys(filters).findIndex((key) => key === "roleId");
        let indexCategory = Object.keys(filters).findIndex((key) => key === "categoryId");

        //if categoryId and the new value is != please, delete roleId filter
        let filtersCopy = _.cloneDeep(filters);
        if (field === "categoryId")
        {
            if (filters.categoryId && filters.categoryId[0] && (filters?.categoryId[0] != value))
            {
                delete filtersCopy.eventId;
                if (prevFilter.roleId && filtersCopy.roleId != prevFilter.roleId) delete filtersCopy.roleId;
            }
        }

        if (field === "roleId")
        {
            if (filters.roleId && filters.roleId[0] && (filters?.roleId[0] != value) && indexCategory != -1 && indexRole < indexCategory)
            {
                delete filtersCopy.eventId;
                delete filtersCopy.categoryId;
                //if (prevFilter.roleId && filtersCopy.roleId != prevFilter.roleId)
            }
        }

        if (filters[field] && field === "businessUnitCode")
        {
            setFilters({ [field as string]: [value] });
        } else if (filters[field])
        {
            setFilters({ ...filtersCopy, [field as string]: [value] });
        }
        // if you want add one or more options for one filter use this commented lines
        // else {
        //     setFilters({ ...filters, [field as string]: [...filters[field], value] });
        // }
    }

    //Delete filters applied
    const filterDelete = (field: string, id: number) =>
    {

        let newFilterArr: any[] = [];
        const filtersCopy = _.cloneDeep(filters);
        let indexRole = Object.keys(filters).findIndex((key) => key === "roleId");
        let indexCategory = Object.keys(filters).findIndex((key) => key === "categoryId");
        let indexTime = Object.keys(filters).findIndex((key) => key === "startDate");

        //check and push data wen when not found the value in this filter , else omit the value
        filtersCopy[field].forEach((positionValue: any) =>
        {
            if (!_.isEqual(positionValue, id)) newFilterArr.push(positionValue);
        })

        filtersCopy[field as string] = newFilterArr;

        // if the key filter haven't value delete the key
        Object.keys(filtersCopy).map(x =>
        {
            if (filtersCopy[x].length === 0)
            {
                delete filtersCopy[x];
            }
        })

        //if categoryId, restart filters by Event(class), if businessUnitCode, reset all
        switch (field)
        {
            case "businessUnitCode":
                delete filtersCopy.categoryId;
                delete filtersCopy.eventId;
                delete filtersCopy.roleId;
                delete filtersCopy.startDate;
            case "categoryId":
                if (filters.categoryId && filters.categoryId[0] && (filters?.categoryId[0] === id) && indexRole != -1 && indexTime != -1 && indexCategory < indexRole && indexCategory < indexTime)
                {
                    delete filtersCopy.eventId;
                    delete filtersCopy.startDate;
                    delete filtersCopy.roleId;
                } else if (filters.roleId && filters.categoryId[0] && (filters?.categoryId[0] === id) && indexRole != -1 && indexCategory < indexRole)
                {
                    delete filtersCopy.roleId;
                    delete filtersCopy.eventId;
                } else if (filters.roleId && filters.categoryId[0] && (filters?.categoryId[0] === id) && indexTime != -1 && indexCategory < indexTime)
                {
                    delete filtersCopy.startDate;
                    delete filtersCopy.eventId;
                } else
                {
                    delete filtersCopy.eventId;
                }
                break;
            case "roleId":
                if (filters.roleId && filters.roleId[0] && (filters?.roleId[0] === id) && indexCategory != -1 && indexTime != -1 && indexRole < indexCategory && indexRole < indexTime)
                {
                    delete filtersCopy.eventId;
                    delete filtersCopy.categoryId;
                    delete filtersCopy.startDate;
                } else if (filters.roleId && filters.roleId[0] && (filters?.roleId[0] === id) && indexCategory != -1 && indexRole < indexCategory)
                {
                    delete filtersCopy.eventId;
                    delete filtersCopy.categoryId;
                } else if (filters.roleId && filters.roleId[0] && (filters?.roleId[0] === id) && indexTime != -1 && indexRole < indexTime)
                {
                    delete filtersCopy.startDate;
                }
                break;
            case "startDate":
                if (filters.startDate && filters.startDate[0] && _.isEqual(filters?.startDate[0], id) && indexCategory != -1 && indexRole != -1 && indexTime < indexCategory && indexTime < indexRole)
                {
                    delete filtersCopy.eventId;
                    delete filtersCopy.categoryId;
                    delete filtersCopy.roleId;
                } else if (filters.startDate && filters.startDate[0] && _.isEqual(filters?.startDate[0], id) && indexCategory != -1 && indexTime < indexCategory)
                {
                    delete filtersCopy.eventId;
                    delete filtersCopy.categoryId;
                } else if (filters.startDate && filters.startDate[0] && _.isEqual(filters?.startDate[0], id) && indexRole != -1 && indexTime < indexRole)
                {
                    delete filtersCopy.indexRole;
                }
                break;

            default:
                break;
        }

        // Set filter without deleted filters to general
        setFilters(filtersCopy)


    }

    // filter a clean copy of database response by filter state
    const filterByState = () =>
    {

        const merge = (a: any, b: any, p: string) => { return a.filter((aa: any) => !b.find((bb: any) => aa[p] === bb[p])).concat(b) };

        let cleanDataResponse: any = _.cloneDeep(copyDataBase.current);

        let filteredResponseAccumulator: object[] = [];

        Object.keys(filters).forEach((key: string, i: number) =>
        {

            if (i > 0) cleanDataResponse = filteredResponseAccumulator;

            if (key != "startDate" && filters[key].length === 1)
            {

                let filteredResponse = cleanDataResponse.filter((x: any) => x[key] === filters[key][0]);

                filteredResponseAccumulator = filteredResponse;

            } else if (key != "startDate" && filters[key].length > 1)
            {
                let mergeClasses: object[] = [];

                filters[key].forEach((id: number) =>
                {

                    let filteredResponse = cleanDataResponse.filter((x: any) => x[key] === id);

                    mergeClasses = merge(mergeClasses, filteredResponse, "eventInstanceId");

                })

                filteredResponseAccumulator = mergeClasses;

            }
            else if (key === "startDate" && filters[key].length === 1)
            {
                let filteredResponse = cleanDataResponse.filter((x: any) => timeConvert(new Date(x[key]).toLocaleString("en-US", { timeZone: process.env.TIME_ZONE }).split(',')[1].substring(1)) >= filters[key][0].start && timeConvert(new Date(x[key]).toLocaleString("en-US", { timeZone: process.env.TIME_ZONE }).split(',')[1].substring(1)) < filters[key][0].end);
                filteredResponseAccumulator = filteredResponse;
            }

        })

        let backupQueryNav :  any = buildFilter(cleanDataResponse, false);
        let dataCategories: any = buildFilter(filteredResponseAccumulator, false);

        // check position of keys to apply filters results to instructors and classes
        let indexRole = Object.keys(filters).findIndex((key) => key === "roleId");
        let indexCategory = Object.keys(filters).findIndex((key) => key === "categoryId");
        let indexEvent = Object.keys(filters).findIndex((key) => key === "eventId");



        if (filters.roleId && indexCategory === -1 && isFirstRender)
        {
            dataCategories.instructors = typeof actualCategoriesAndClasses.instructors === 'undefined' ? backupQueryNav.instructors : actualCategoriesAndClasses.instructors;
        }
        else if (filters.roleId && indexRole < indexCategory)
        {
            dataCategories.categories = typeof actualCategoriesAndClasses.categories === 'undefined' ? backupQueryNav.categories : actualCategoriesAndClasses.categories;
            dataCategories.instructors = typeof actualCategoriesAndClasses.instructors === 'undefined' ? backupQueryNav.instructors : actualCategoriesAndClasses.instructors;
        }

        if (filters.categoryId && indexRole === -1)
        {
            if (prevFilter.categoryId && (prevFilter?.categoryId[0] != filters?.categoryId[0]))
            {
                dataCategories.categories = typeof actualCategoriesAndClasses.categories === 'undefined' ? backupQueryNav.categories : actualCategoriesAndClasses.categories;
                setPrevFilter(filters);
            } else
            {
                dataCategories.categories = typeof actualCategoriesAndClasses.categories === 'undefined' ? backupQueryNav.categories : actualCategoriesAndClasses.categories;
                setPrevFilter(filters);
            }
        } else if (filters.categoryId && indexRole > indexCategory)
        {
            if (prevFilter.categoryId && (prevFilter?.categoryId[0] != filters?.categoryId[0]))
            {
                dataCategories.instructors = typeof actualCategoriesAndClasses.instructors === 'undefined' ? backupQueryNav.instructors : actualCategoriesAndClasses.instructors;
                dataCategories.categories = typeof actualCategoriesAndClasses.categories === 'undefined' ? backupQueryNav.categories : actualCategoriesAndClasses.categories;
                setPrevFilter(filters);
            } else if (indexEvent != -1)
            {
                dataCategories.categories = typeof actualCategoriesAndClasses.categories === 'undefined' ? backupQueryNav.categories : actualCategoriesAndClasses.categories;
                dataCategories.instructors = typeof actualCategoriesAndClasses.instructors === 'undefined' ? backupQueryNav.instructors : actualCategoriesAndClasses.instructors;
                dataCategories.classes = typeof actualCategoriesAndClasses.classes === 'undefined' ? backupQueryNav.classes :  actualCategoriesAndClasses.classes;
                setPrevFilter(filters);
            } else
            {
                dataCategories.categories = typeof actualCategoriesAndClasses.categories === 'undefined' ? backupQueryNav.categories : actualCategoriesAndClasses.categories;
                dataCategories.instructors = typeof actualCategoriesAndClasses.instructors === 'undefined' ? backupQueryNav.instructors : actualCategoriesAndClasses.instructors;
                setPrevFilter(filters);
            }

        }

        if (filters.eventId && filters.roleId)
        {
            //please do nothing
        } else if (filters.eventId)
        {
            dataCategories.classes = typeof actualCategoriesAndClasses.classes === 'undefined' ? backupQueryNav.classes :  actualCategoriesAndClasses.classes;
        }


        setCategoriesAndClasses(dataCategories);
        // ak merge?
        setClassesByDateFiltered(buildCategoryArray(filteredResponseAccumulator));

    }

    //merge, original dates with dates filtered
    const dataByDate = (data: Array<Object>) =>
    {

        // we need always have 14 days
        let actualDay: Date = new Date();
        const merge = (a: any, b: any, p: string) => { return a.filter((aa: any) => !b.find((bb: any) => aa[p] === bb[p])).concat(b) };
        let fourteenDates: any = [];

        for (let i = 0; i < 14; i++)
        {
            fourteenDates.push(dateHandler(new Date(actualDay.getTime() + (24 * 60 * 60 * 1000) * i)));
        }

        let withoutFilters = buildCategoryArray(data);
        let withFilters = _.cloneDeep(classesByDateFiltered);

        withoutFilters = merge(withoutFilters, fourteenDates, "numberDate");

        let dataMerge: Array<DayType> = [];

        //for each day check if you can find this in the filtered day, if you can't delete classes and push day in merge array else if you can push all filtered day encountered
        withoutFilters.forEach((day: DayType) =>
        {
            let find: DayType | undefined = withFilters.find((element: DayType) => element.numberDate === day.numberDate);
            if (!find)
            {
                day.classesData = [];
                dataMerge.push(day);
            } else
            {
                dataMerge.push(find);
            }
        })


        //set state for all days with or without classes
        setClassesByDate(dataMerge);
        //set current day for scrolling effects
        setCurrentDay(dataMerge[0]);
    }


    const clearAllFiltersHandler = () =>
    {
        setFilters({});
    }
    //#endregion

    const heroData = {
        hero_background: pageData?.hero_background,
        mobile_background: pageData?.mobile_background,
        hero_title: pageData?.title,
        hero_description: pageData?.hero_description,
    };

    const [showFilters, setShowFilters] = useState(false);


    const handleModalClose = async () => {
        setReserveModal(false);
        if ((typeof reserveModal === "object") && reserveModal.registered) {
            navigate("/members/upcoming-classes");
        }
    }


    useEventListener("keydown", escHandler, typeof window !== "undefined" ? window : null, false);
    useEventListener("scroll", handleScroll, typeof document !== "undefined" ? document : null);

    return (
        <>
            {pageData != null && <Helmet
                title={getSeoTitle(pageData, "Schedule")}
                description={getSeoDescription(pageData, "Schedule")}
                keywords={getSeoKeywords(pageData, "")} 
            >
                <link rel="canonical" href={`${process.env.GATSBY_SITE_URL}/scheduler`} />
            </Helmet>}
            {heroData.hero_background ? <HeroBlurred data={heroData} height={70} /> : <WarningComponent template={'Scheduler Page'} message={'Hero background'}/>}
            {navArray.length > 0 ?
                isDesktop ?
                    <DateNav ref={dateNavRef} dates={navArray} current={currentDay} onDateClick={selectDay} />
                    :
                    <>
                        <DateNavMobile ref={dateNavRef} dates={navArray} current={currentDay} onDateClick={selectDay} />

                        <button className={`${styles.downloadSchedule} print-hidden ${!isDesktop ? styles.hiddenPrint : "" }`} onPointerDown={()=> window ? window.print() : null}>
                            {printerIcon}
                            <span>Download your schedule</span>
                        </button>

                        <div className={`${styles.toggleFilters} print-hidden`}>
                            <div onClick={() => setShowFilters(!showFilters)} className={styles.filterIconWrapper}>
                                <span>{FilterIcon}</span>
                                <span>Filters</span>
                            </div>
                            {
                                Object.keys(filters).length ? (
                                    <div>
                                        <span className={styles.clearFilterMobile} onClick={() => clearAllFiltersHandler()}>{clearFilterIcon}</span>
                                    </div>
                                ) : null
                            }
                        </div>
                    </> :
                null
            }
            <main className={`${styles.mainGrid} print-block`}>
                {
                    (showFilters || isDesktop) && (
                        <aside className={`${styles.categoriesSidebar} print-hidden`}>
                            <FilterNav immutableData={classFilter} categoriesFilters={actualCategoriesAndClasses} totalClasses={actualCategoriesAndClasses} onFilter={filterSelect} filtersApplied={filters} onDelete={filterDelete} clearAll={clearAllFiltersHandler} />
                        </aside>
                    )
                }
                {(classesByDateFiltered.length) ? <section className={styles.classesContainer} ref={containerRef}>
                    {classesByDate.map((date: any, i: number) => (
                        <Day
                            isMobile={!isDesktop}
                            key={date.numberDate}
                            handleClass={setCurrentClass}
                            hourSplitter={handleSplit}
                            {...date}
                            haveClasses={true}
                            spinner={setSpinner}
                            reserveModal={setReserveModal}
                        />
                    ))}
                </section> : <section className={styles.classesContainer} ref={containerRef}> {!showSpinner && <Day haveClasses={false} />} </section>}
            </main>
            {showError && 
            <MembersModal title="Error" handleClose={() => setError(false)} >
                <div className={styles.errorAdvice}>
                    <h3>An error has occurred</h3>
                    <p>Something went wrong, please try again later</p>
                 </div>
            </MembersModal>
            }
            {currentClass && (
                <ClassPopup
                    {...currentClass}
                    hourSplitter={handleSplit}
                    handleClass={setCurrentClass}
                    {...{ isDesktop }}
                    spinner={setSpinner}
                    reserveModal={setReserveModal}
                />
            )}
            {showSpinner && <MembersModal title="loading" handleClose={handleModalClose} loading={true} />}
            {reserveModal && <MembersModal handleClose={handleModalClose} title={ !(typeof reserveModal === "boolean") ? reserveModal.title : "" }>{!(typeof reserveModal === "boolean") ? reserveModal.message : "" }</MembersModal>}
            {unauthorized && 
            <MembersModal handleClose={()=>isWindow && window.location.reload()} title={"Unauthorized"} >
                <p>Your session has expired or your credentials are invalid.</p>
            </MembersModal>
            }
        </>
    )
};

export default Scheduler;
