import React, { PropsWithChildren, ReactElement } from "react";
import { watchIcon, calendarIcon, instructorIcon, mapIcon } from "./../../../../../shared/icons"
import styles from "./DisplayInfo.module.scss"

interface props {
    day: any
    month: any
    numberDate: any
    startHour: string
    durationTime: string
    location: string
    studio: string
    instructor: string
}

const DisplayInfo = (props: PropsWithChildren<props>): ReactElement => {

    const {
        day,
        month,
        numberDate,
        startHour,
        durationTime,
        location,
        studio,
        instructor
    } = props

    let cutDate = numberDate.split("/")[1]

    return (
        <div className={styles.displayWrapper}>
            <p className={styles.dateText}>{calendarIcon} {day.large}, {month.short} {cutDate} - {startHour}</p>
            <p className={styles.dateText}>{watchIcon}{durationTime} </p>
            <p className={styles.locationText}>{mapIcon} {location} - {studio}</p>
            <p className={styles.instructorText}>{instructorIcon}{instructor}</p>
        </div>
    )
}


export default DisplayInfo

