import React, {
    PropsWithChildren,
    ReactElement,
    useState,
    useEffect,
} from "react";
import _ from "lodash";
import {
    printerIcon,
    closeBtnAlt,
    chekIcon,
    clearFilterIcon,
    searchIcon,
} from "./../../../shared/icons";
import styles from "./FilterNav.module.scss";
import { filterHours, ObjectFilter, categories, gym, singleClass, instructor } from "./FilterNavInterfaces";
import { sortByKey } from "../../../shared/helpers";

const FilterNav = (props: PropsWithChildren<any>): ReactElement => {
    const [currentGym, setCurrentGym] = useState({});
    const [currentInstructor, setCurrentInstructor] = useState<any>([]);
    const [currentTime, setCurrentTime] = useState<any>([]);
    const [currentCategory, setCurrentCategory] = useState<any>({});
    const [currentClass, setCurrentClass] = useState<any>([]);
    const [search, setSearch] = useState<string>("");

    const {
        clearAll,
        filtersApplied,
        immutableData: { gyms, categories },
        totalClasses,
        onFilter,
        onDelete,
        categoriesFilters,
    } = props;

    //const categoriesFiltered = categoriesFilters.categories;
    const instructorsFiltered = categoriesFilters.instructors;
    const classesFiltered = categoriesFilters.classes;

    useEffect(() => {
        if (filtersApplied.businessUnitCode) {
            setCurrentGym(filtersApplied.businessUnitCode[0]);
        } else if (currentGym) {
            setCurrentGym({});
        }
        if (filtersApplied.categoryId) {
            setCurrentCategory(filtersApplied.categoryId[0]);
        } else if (currentCategory) {
            setCurrentCategory({});
        }
        if (filtersApplied.eventId) {
            setCurrentClass([filtersApplied.eventId[0]]);
        } else if (currentClass) {
            setCurrentClass([]);
        }
        if (filtersApplied.roleId) {
            setCurrentInstructor([filtersApplied.roleId[0]]);
        } else if (currentInstructor) {
            setCurrentInstructor([]);
        }
        if (filtersApplied.startDate) {
            setCurrentTime([filtersApplied.startDate[0]]);
        } else if (currentTime) {
            setCurrentTime([]);
        }
    }, [filtersApplied]);

    let totalResults: number = 0;

    totalClasses.classes?.forEach((singleClass: any) => {
        totalResults = totalResults + singleClass.quantity;
    });

    const clickHandler = (
        field: string,
        fieldValue: number | string | filterHours,
        objectFilter: ObjectFilter
    ) => {
        switch (field) {
            case "businessUnitCode":
                if (currentGym === fieldValue) {
                    onDelete(objectFilter.field, objectFilter.id);
                } else {
                    onFilter(field, fieldValue, objectFilter);
                }
                break;
            case "categoryId":
                if (currentCategory === fieldValue) {
                    onDelete(objectFilter.field, objectFilter.id);
                } else {
                    onFilter(field, fieldValue, objectFilter);
                }
                break;
            case "eventId":
                if (currentClass[0] === fieldValue) {
                    onDelete(objectFilter.field, objectFilter.id);
                } else {
                    onFilter(field, fieldValue, objectFilter);
                }
                break;
            case "roleId":
                if (currentInstructor[0] === fieldValue) {
                    onDelete(objectFilter.field, objectFilter.id);
                } else {
                    onFilter(field, fieldValue, objectFilter);
                }
                break;
            case "startDate":
                if (_.isEqual(fieldValue, currentTime[0])) {
                    onDelete(objectFilter.field, objectFilter.id);
                } else {
                    onFilter(field, fieldValue, objectFilter);
                }
                break;
            default:
                break;
        }
    };


    sortByKey("categoryName", categories)
    sortByKey("className", classesFiltered)
    
    return (
        <div className={styles.categoriesListWrapper}>
            

            <button className={`${styles.actionBtn} ${styles.printerBtn}`} onPointerDown={()=> window ? window.print() : null}>
                {printerIcon}
                <span>Print your schedule</span>
            </button>

            { (Object.keys(filtersApplied).length > 0) && <button
                className={`${styles.actionBtn} ${styles.clearAllBtn}`}
                onClick={() => clearAll()}
            >
                {clearFilterIcon}
                <span>Clear Filters</span>
            </button>}
            
            <h2 className={styles.totalClassesTitle}>Available Classes</h2>

            {totalResults && (
                <p className={styles.totalClasses}>{totalResults} results</p>
            )}

           

            <div className={styles.clubPicker}>
                <h2>Select a club</h2>

                <div className={styles.gymsWrapper}>
                    {gyms?.map((gym: gym, i: number) => (
                        <button
                            className={`${styles.filterGym} ${
                                gym.businessUnitCode === currentGym
                                    ? styles.active
                                    : ""
                            }`}
                            key={"gym" + i + gym.businessUnitCode}
                            onClick={() =>
                                clickHandler(
                                    "businessUnitCode",
                                    gym.businessUnitCode,
                                    {
                                        id: gym.businessUnitCode,
                                        field: "businessUnitCode",
                                    }
                                )
                            }
                        >
                            {chekIcon}
                            {gym.location}
                        </button>
                    ))}
                </div>
            </div>

            <div className={styles.categoryPicker}>
                <h2>Category</h2>

                {categories?.map((cat: categories, i: number) => (
                    <div key={"cat" + i + cat.categoryId}>
                        <button
                            className={`${styles.filterCategories} ${
                                cat.categoryId === currentCategory
                                    ? styles.active
                                    : ""
                            }`}
                            onClick={() => {
                                clickHandler("categoryId", cat.categoryId, {
                                    id: cat.categoryId,
                                    field: "categoryId",
                                });
                            }}
                        >
                            {chekIcon}
                            <span>{cat.categoryName}</span>
                        </button>

                        <ul
                            className={`${styles.classesList} ${
                                cat.categoryId === currentCategory
                                    ? styles.active
                                    : ""
                            }`}
                        >
                            {classesFiltered.map(
                                (single: singleClass, i: number) => (
                                    <li
                                        key={`single.eventId` + i}
                                        onClick={() => {
                                            clickHandler(
                                                "eventId",
                                                single.eventId,
                                                {
                                                    id: single.eventId,
                                                    field: "eventId",
                                                }
                                            );
                                        }}
                                    >
                                        <button
                                            className={` ${styles.list} ${
                                                currentClass?.find(
                                                    (element: number) =>
                                                        element ===
                                                        single.eventId
                                                )
                                                    ? styles.active
                                                    : ""
                                            }`}
                                        >
                                            {single.className} (
                                            {single.quantity}) {closeBtnAlt}
                                        </button>
                                    </li>
                                )
                            )}
                        </ul>
                    </div>
                ))}
            </div>

            <div className={styles.instructorsSearch}>
                <h2>Instructor</h2>

                <div className={styles.searchInput}>
                    <input
                        type="search"
                        onChange={e => setSearch(e.currentTarget.value)}
                        placeholder={"Search instructor"}
                    />
                    {searchIcon}
                </div>

                <div className={styles.instructorsWrapper}>
                    {instructorsFiltered?.map(
                        (instructor: instructor, i: number) =>
                            instructor.instructor.toLowerCase().includes(
                                search.toLowerCase()
                            ) && (
                                <button
                                    className={`${styles.filterInstructors} ${
                                        currentInstructor.find(
                                            (element: string) =>
                                                element === instructor.roleId
                                        )
                                            ? styles.active
                                            : ""
                                    }`}
                                    key={"inst" + i + instructor.roleId}
                                    onClick={() => {
                                        clickHandler(
                                            "roleId",
                                            instructor.roleId,
                                            {
                                                id: instructor.roleId,
                                                field: "roleId",
                                            }
                                        );
                                    }}
                                >
                                    {chekIcon}
                                    {instructor.instructor?.toLowerCase()}
                                </button>
                            )
                    )}
                </div>
            </div>

            <div className={styles.timePicker}>
                <h2>Time</h2>

                <div className={styles.timesWrapper}>
                    <button
                        className={
                            currentTime.find((element: string) =>
                                _.isEqual(element, { start: 5, end: 12 })
                            )
                                ? styles.active
                                : ""
                        }
                        onClick={() => {
                            clickHandler(
                                "startDate",
                                { start: 5, end: 12 },
                                {
                                    id: { start: 5, end: 12 },
                                    field: "startDate",
                                }
                            );
                        }}
                    >
                        {chekIcon}Morning
                    </button>

                    <button
                        className={
                            currentTime.find((element: string) =>
                                _.isEqual(element, { start: 12, end: 17 })
                            )
                                ? styles.active
                                : ""
                        }
                        onClick={() => {
                            clickHandler(
                                "startDate",
                                { start: 12, end: 17 },
                                {
                                    id: { start: 12, end: 17 },
                                    field: "startDate",
                                }
                            );
                        }}
                    >
                        {chekIcon}Afternoon
                    </button>

                    <button
                        className={
                            currentTime.find((element: string) =>
                                _.isEqual(element, { start: 17, end: 23 })
                            )
                                ? styles.active
                                : ""
                        }
                        onClick={() => {
                            clickHandler(
                                "startDate",
                                { start: 17, end: 23 },
                                {
                                    id: { start: 17, end: 23 },
                                    field: "startDate",
                                }
                            );
                        }}
                    >
                        {chekIcon} Evening
                    </button>
                </div>
            </div>
        </div>
    );
};

export default FilterNav;


