import React, { forwardRef, ReactElement, useEffect, useState } from "react";
import useWindowWidth, { heroDesktopMinWidth } from "../../../hooks/useWindowWidth";
import { arrows } from "../../../shared/icons"
import styles from "./DateNav.module.scss"

interface DateNavProps
{
    dates: any,
    current: any,
    onDateClick: (day: Date) => void
}

interface navArray
{
    UTCdate: Date,
    classesData: any[],
    dayName: dayAndMonthNames,
    monthName: dayAndMonthNames,
    numberDate: string
}

interface dayAndMonthNames
{
    large: string, short: string
}

const DateNav = forwardRef<HTMLElement, DateNavProps>((props, ref): ReactElement =>
{
    const { dates, current, onDateClick } = props
    const isDesktop = useWindowWidth(900);

    const datesCopy = [...dates]
    const datesCopyMobile = [...dates]

    const firstSeven = datesCopy.splice(0, (dates.length / 2))
    const secondSeven = datesCopy.splice(0, (dates.length))

    const [navArray, setNavArray] = useState(new Array<navArray>());

    useEffect(() =>
    {
        if (isDesktop)
        {
            setNavArray(firstSeven);
        } else
        {
            setNavArray(datesCopy);
        }
    }, [dates])

    useEffect(() =>
    {
        if (isDesktop)
        {
            if (current && current?.numberDate === secondSeven[0].numberDate)
            {
                setNavArray(secondSeven)
            } else if (current && current?.numberDate === firstSeven[firstSeven.length - 1].numberDate)
            {
                setNavArray(firstSeven)
            };
        }
    }, [current])

    useEffect(() =>
    {
        if (isDesktop)
        {
            if (current) firstSeven.find((x) => x.numberDate === current.numberDate) ? setNavArray(firstSeven) : setNavArray(secondSeven)
        } else
        {
            setNavArray(datesCopyMobile)
        }
    }, [isDesktop])



    return (
        <nav {...{ ref }} className={`${styles.DateNavWrapper} print-hidden`}>
            <div className={`${styles.elementsNavWrapper} ${!isDesktop ? styles.mobileGrid : ""}`}>

                {isDesktop ? <div className={`${styles.elementNav} ${styles.arrow} ${firstSeven[0] === navArray[0] ? styles.disabled : ""}`} onClick={() => setNavArray(firstSeven)} >
                    {arrows.left}
                </div> : <div className={styles.blurredBorderLeft}></div>}

                {navArray.map((date: any, i: number) => (
                    <div
                        className={` ${styles.elementNav} 
                    ${(firstSeven[0] === navArray[0] && i === 0) ? styles.currentDay : ""} 
                    ${date.numberDate === current?.numberDate ? styles.focusDay : ""}
                    `}
                        key={i}
                        onClick={() => onDateClick(date)}
                    >
                        <p className={styles.today}>{date.numberDate}</p>
                        <p className={styles.today}>{firstSeven[0] === navArray[0] && i === 0 ? "TODAY" : date.dayName.short}</p>
                    </div>
                ))}

                {isDesktop ? <div className={`${styles.elementNav} ${styles.arrow} ${secondSeven[0] === navArray[0] ? styles.disabled : ""} `} onClick={() => setNavArray(secondSeven)} >
                    {arrows.right}
                </div> : <div className={styles.blurredBorderRight}></div>}


            </div>
        </nav>
    )
});

export default DateNav;