import React from "react"
import { Router } from "@reach/router"
import { withPrefix } from 'gatsby'
import Scheduler from "../templates/Scheduler_page";
import RedirectDefault from "../components/RedirectDefault";
import { isWindow } from "../shared/helpers";

const ClientApp = () => (
    <Router basepath={withPrefix("/scheduler")}>
        <Scheduler path="/"/>
        <RedirectDefault path="*" location={isWindow ? location : ""} default />
    </Router>
);

export default ClientApp;