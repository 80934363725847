import React, { PropsWithChildren, ReactElement, useEffect, useState } from "react";
import styles from "./ClassCard.module.scss"
import { mapIcon, instructorIcon, arrows } from "../../../../shared/icons"
import useWindowWidth from "../../../../hooks/useWindowWidth";
import { Link, navigate } from "gatsby";
import WebAccountService from "../../../../services/WebAccountService";
import Spinner from "../../../../components/shared/Spinner";
import MembersModal from "../../../Members_page/Shared/MembersModal";
import { IReserveModal } from "../..";

interface props {
    businessUnitCode: number,
    eventInstanceId: number,
    className: string,
    categoryName: string,
    startDate: string,
    endDate: string,
    totalDuration: string,
    location: string,
    instructor: string,
    shortDescription: string,
    PrismicRelation: string,
    onPointer: any,
    hourSplitter: any,
    noFeeRequired: number,
    maxCapacity: number,
    remainingSpots: number,
    eventInstanceMosoId: number,
    reserved: boolean,
    loading : (param:boolean)=> void,
    reserveModal : (param: IReserveModal )=> void,
}


const ClassCard = (props: PropsWithChildren<props>): ReactElement => {
    const {
        eventInstanceId,
        className,
        categoryName,
        startDate,
        endDate,
        totalDuration,
        location,
        instructor,
        PrismicRelation,
        hourSplitter,
        shortDescription,
        noFeeRequired,
        maxCapacity,
        remainingSpots,
        eventInstanceMosoId,
        reserved,
        loading,
        reserveModal
    } = props


    const [isLoggedIn, setLogged] = useState(false);

    let currentStartHour = new Date(startDate).toLocaleTimeString("en-US", { timeZone: process.env.TIME_ZONE });
    let currentEndHour = new Date(endDate).toLocaleTimeString("en-US", { timeZone: process.env.TIME_ZONE });

    const isDesktop = useWindowWidth(768)

    const openPopupHandler = () => {
        if (isDesktop) {
            props.onPointer();
        }

    }

    useEffect(() => {
        if (localStorage.getItem("jwt-token")) {
            setLogged(true);
        }
        else {
            setLogged(false);
        }
    }, []);


    const handleReserve = async () => {
        // -- Open spinner
        loading(true);
        const response = await WebAccountService.registerClass({ eventInstanceId: eventInstanceMosoId });
        // -- Close spinner
        loading(false);
        if (response.success) {
            reserveModal({
                title : "Success",
                message : "You have been registered to the class successfully",
                registered : true
            });
        }
        else {
            reserveModal({
                title : "Error",
                message : response.message
            });
        }
        
    }

    return (
        <div className={styles.cardWrapper}>
            <div className={styles.left} onPointerDown={openPopupHandler}>
                <h3 className={styles.classTitle}>
                    {className}
                    <span>{shortDescription}</span>
                </h3>
                <p className={styles.classHours}>
                    {hourSplitter(currentStartHour)} -  {hourSplitter(currentEndHour)}
                </p>
            </div>
            <div className={styles.right}>
                <div className={styles.textWrapper}>
                    <span className={styles.icon}>{instructorIcon}</span>
                    <p>
                    {!isDesktop && 'with '}{instructor}
                    </p>
                </div>
                <div className={styles.textWrapper}>
                    <span>{mapIcon}</span>
                    <p>
                        {location}
                    </p>
                </div>
            </div>

            <button className={`${styles.buttonMobile} print-hidden`} onPointerDown={props.onPointer}>
                More info
                <span>{arrows.right}</span>
            </button>

            <div className={`${styles.buttonSection} print-hidden`}>
                {(!(noFeeRequired) || !isLoggedIn ) &&<Link to={'/try-us'} state={{ classSelected: props.className, club: props.businessUnitCode, date: startDate }} className={styles.button}>Try class</Link>}
                {noFeeRequired && isLoggedIn && <button onClick={handleReserve} className={`${reserved? styles.disabled : ""} ${styles.buttonInverse}`}>Reserve</button>}
            </div>
                {reserved && <p className={styles.reservedText}>Reserved</p>}
        </div>
    )
};

export default ClassCard;